import {
  AppBar,
  Box,
  Drawer,
  Toolbar,
  Typography,
}                   from '@mui/material';
import PropTypes    from 'prop-types';
import {
  useEffect,
  useMemo,
  useState,
}                   from 'react';
import { isMobile } from 'react-device-detect';
import {
  useDispatch,
  useSelector,
}                   from 'react-redux';

import { miscReduxData }   from '../app/slices/miscSlice';
import { logout }          from '../app/slices/profileSlice';
import { checkTokenValid } from '../app/utils/checkTokenValid';
import { userRoleLabel }   from '../app/utils/userRolesConst';

import SelUnternehmen from "./SelUnternehmen";

import {
  AccountMenu,
  Copyright,
  LeftMenu,
  Logo,
  LogoMobile,
  NavElement,
  PreloaderLinear,
} from './';


const navPrams = {
  width: isMobile ? 5 : 240,
  top: 64,
};

const parentActiveColor = '#FF5722';

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => {
    return state.profile;
  });
  const miscData = useSelector(miscReduxData);
  const [userRole, setUserRole] = useState('unknown role');

  const AccountInfo = (props) => {
    return <Box align="right" sx={{ flexGrow: 1 }}>{props.children}</Box>;
  };

  const UserInfo = (props) => {
    return <Typography
      sx={{
        mr: 1,
        fontSize: '12px',
        textShadow: '.15em .15em 0.17em #000000',
      }}
    >{props.children}</Typography>;
  };

  const isLogged = useMemo(() => {
    return checkTokenValid();
  }, [profile]);

  if (!isLogged) {
    dispatch(logout());
  }

  useEffect(() => {
    const roles = profile?.authData?.userData?.roles;

    if (!roles) {
      return;
    }
    const roleLabel = userRoleLabel(miscData, roles);
    setUserRole(roleLabel);
  }, [profile]);

  return (
    <Box>
      <>
        <AppBar position="sticky" title={process.env.REACT_APP_NAME} style={{ zIndex: 1201 }}>
          <Toolbar sx={{ p: { sm: 0, xs: 0 } }}>

            {isMobile && <LogoMobile/>}

            {!isMobile && <Logo accent={isLogged} width={navPrams.width}/>}
            {!isMobile &&  isLogged && profile.spaces.length>0 && <>
              <Box sx={{ width: '300px' }}>
                <SelUnternehmen/>
              </Box>

              <AccountInfo>
                <UserInfo>{userRole}</UserInfo>
                <UserInfo>{profile?.authData?.userData?.name}</UserInfo>
              </AccountInfo>
              <AccountMenu/>
            </>
            }
          </Toolbar>
        </AppBar>
        <PreloaderLinear/>

        {isLogged && !isMobile && <nav style={{ marginTop: navPrams.top }}>
          <Drawer
            variant="permanent"
            PaperProps={{
              sx: {
                mt: 8,
                width: navPrams.width,
              },
            }}
            anchor="left"
            ModalProps={{keepMounted: true}}
          >
            <LeftMenu>
              <NavElement route={"/aktuelle-meldungen"}
                          title={"aktuelle Meldungen"}
                // iconCallback={()=>{}}
                          parentActiveColor={parentActiveColor}
                          key={"k1"}
              />
              <NavElement route={"/meldungen-bearbeiten"}
                          title={"Meldungen bearbeiten"}
                // iconCallback={()=>{}}
                          parentActiveColor={parentActiveColor}
                          key={"k2"}
              />
              <NavElement route={"/show-internet"}
                          title={"Vorschau - Anzeige im Internet"}
                // iconCallback={()=>{}}
                          parentActiveColor={parentActiveColor}
                          key={"k3"}
              />

            </LeftMenu>
          </Drawer>
        </nav>}
      </>
      { isMobile &&
      <Box sx={{ padding: '0 8px', mt: -6, paddingLeft: isLogged ? `${navPrams.width + 8}px` : '8px' }}>
        {children}
      </Box>
      }
      { !isMobile &&
        <Box sx={{ padding: '0 8px', mt: -6, paddingLeft: isLogged ? `${navPrams.width + 8}px` : '8px' }}>
          {children}
          <Box mt={2}>
            <Copyright/>
          </Box>
        </Box>
      }
    </Box>
  );
};

Layout.propTypes = { children: PropTypes.element.isRequired };
