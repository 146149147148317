import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalAlertData: {
    show: false,
    title: '...',
    subtitle: '...',
  },
  userRoles: [
    {
      value: 'HAVARIE_FACHADMIN',
      label: 'Fachadmin',
      description: 'Benutzer der App',
    },
    {
      value: 'HAVARIE_USER',
      label: 'Benutzer',
      description: 'Benutzer der App',
    },
  ],
  userSpaces : [
    {
      value: 'MIDEWA',
      label: 'MIDEWA',
    },
    {
      value: 'VWD',
      label: 'VWD',
    },
  ],
  globalAppData: {unternehmen: '...'},

};

const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setGlobalAlertData (state, action) {
      state.globalAlertData = {...state.globalAlertData, ...action.payload};
      if (action.payload && action.payload.subtitle) {
        state.globalAlertData.subtitle = action.payload.subtitle;
      } else if (action.payload && action.payload.detail) {
        state.globalAlertData.subtitle = action.payload.detail;
      } else {
        state.globalAlertData.subtitle = '... nop :/';
      }
    },
    setGlobalAppData( state, action ) {
      state.globalAppData={...state.globalAppData, ...action.payload};
    },
  },
});

export const miscReduxData = (state) => {return state.misc;};
export const { setGlobalAlertData } = miscSlice.actions;
export const { setGlobalAppData} = miscSlice.actions;
export default miscSlice.reducer;
