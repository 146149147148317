export const handleApiProblem = (error, callback) => {
  console.error('ERROR: ', error);

  const networkError = (error && error.code && error.code === 'ERR_NETWORK');

  if (networkError) {
    return callback({show: true, title: error.name || 'Network error', subtitle: error.message || 'Network error occurs, check internet connection.'});
  }

  let errorData = (error && error.response && error.response.data);

  if (errorData === "") {
    errorData = {show: true, title: error.name || 'Network error', subtitle: error.message || 'Network error occurs.'};
  }

  const errorStatus = (error && error.response && error.response.status);

  if (errorStatus < 400) {
    return;
  }

  if (errorStatus === 401) {
    callback({ title: 'Unauthorized', subtitle: 'Sie werden abgemeldet', show: true });
  } else if (errorStatus === 403) {
    callback({ title: 'Forbidden', subtitle: 'Sie werden abgemeldet', show: true });
  } else {
    callback(errorData);
  }
};
