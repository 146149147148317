import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grow,
  Typography,
} from '@mui/material';
import PropTypes from "prop-types";

import {stringToColor} from "../app/utils/stringToColor";

const growTimeout = 2789;
const q = 1.2;

function stringAvatar(name) {
  const words = name.split(' ');
  const firstLetter = words[0][0];
  const secondLetter = words.length > 1 ? words[1][0] : '';
  const style = {...avatarStyle, bgcolor: stringToColor(name)};
  return {
    sx: {...style},
    children: `${firstLetter}${secondLetter}`,
  };
}

const avatarStyle = {
  letterSpacing: 0,
  border: "0.005em outset #cccccc",
  boxShadow: ".075em .075em .1em #bbbbbb",
  fontSize: `${4.5/(q-0.1)}rem`,
  fontFamily: 'Monospace',
  fontWeight: "bold",
  width: 120/q,
  height: 120/q,
  bgcolor: stringToColor(name),
};

const cardStyle = {
  width: 270/q,
  height: 260/q,
  m: 3,
  mb: 3,
  bgcolor: '#f5f9af',
  border: 0,
  boxShadow: ".4em .4em .7em #cccccc",
  transition: 'transform 1s',
  transformStyle: 'preserve-3d',
};

const appTitleStyle = {
  ml: ".82em",
  mt: "-.3em",
  fontWeight: "bold",
  fontFamily: "Monospace",
  fontSize: "0.88rem",
  textTransform: "uppercase",
  textOverflow: "ellipsis",
  overflow: "hidden",
  wordWrap: 'break-word',
  textShadow: ".2em .2em .5em #888888",
  width: `${132/q}px`,
  height: `${124/q}px`,
};

const descriptionStyle = {
  mt: 2.5,
  color: "#444",
  height: `${100/(q+0.2)}px`,
  fontSize: "0.8rem",
  textOverflow: "ellipsis",
  textShadow: ".05em .05em .15em #888888",
  overflow: "hidden",
};

export function WebappTile({appTitle, description, url, version}) {
  return (
    <Grow
      in={true}
      style={{transformOrigin: '0 0 0'}}
      timeout={growTimeout}
    >
      <Card sx={cardStyle} variant="elevation" square title={`${appTitle} öffnen`}>
        <CardActionArea href={url} target='_blank' sx={{...cardStyle, m: 0}}>
          <CardContent>
            <Typography sx={{display: "inline-flex", mb:-1}} component="div">
              <Avatar {...stringAvatar(appTitle)} variant="square"/>
              <Box sx={{...appTitleStyle, color: stringToColor(appTitle)}}>
                {appTitle}&nbsp;
              </Box>
            </Typography>
            <Box sx={descriptionStyle}>
              {description}
            </Box>
            <Chip size="small" label={version} sx={{mt: -5, fontSize: "0.7rem",  textShadow: ".05em .05em .15em #777777"}}/>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  );
}

WebappTile.propTypes = {
  appTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};
