import {
  Box,
  Typography,
}                from '@mui/material';

export const LogoMobile = () => {
  return (
    <Box
      sx={{
        ml:'10px',
        display:'flex',
        flexDirection:'row',
        // alignItems:'center',
        gap:'20px',
        w: '100vh',
      }}
    >
        <Box
          component="img"
          // sx={{ maxHeight: '64 / 3.2' }}
          src="/logo.png"
        />
        <Typography
          sx={{
            pl: .25,
            whiteSpace: 'nowrap',
            overflow: 'clip',
            color: '#FFFFFF',
            textTransform: 'uppercase',
            fontSize: '1.3em',
            textShadow: '.1em .1em 0.15em #000000',
          }}>
          Havariemeldungen
        </Typography>
      </Box>


  );
};

