import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";


const ConfirmDialog = ({ confirmMessage, onConfirmYes, onConfirmNo }) => {


  return (
    <>
      <Box style={{
        position: 'absolute',
        width: '80%',
        // minHeight: '80px',
        left: '50px',
        top: '30%',
        zIndex: 65000,
        border: '1px solid gray',
        padding:'10px 5px 20px 5px',
        boxShadow:'2px 2px 2px gray',
        backgroundColor:'white',


      }}>
        <Typography variant="h5" sx={{textAlign:'center'}}>{confirmMessage}</Typography>

        <Stack direction="row" spacing={2}
               justifyContent="center"
               alignItems="center"
               sx={{width:'100%' , mt:'10px'}}>
          <Button variant="contained"
                  onClick={onConfirmYes}
          >Ja</Button>
        <Button variant="contained"
                onClick={onConfirmNo}
        >Nein</Button>
        </Stack>
      </Box>
    </>
  );

};

ConfirmDialog.propTypes = {
  confirmMessage: PropTypes.string.isRequired,
  onConfirmYes: PropTypes.func.isRequired,
  onConfirmNo: PropTypes.func.isRequired,
};

export default ConfirmDialog;


