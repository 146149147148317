import { PropTypes } from 'prop-types';
import {Fragment, useEffect} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logout } from '../app/slices/profileSlice';
import { checkTokenValid } from '../app/utils/checkTokenValid';
import { detach } from '../app/utils/detach';
import errorPages from '../pages/Error/t9n/de.json';

export const RouteGuard = ({ children, roles }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => {return state.profile;});
  const navigate = useNavigate();

  useEffect(() => {
    const tokenValid = checkTokenValid();
    if (!tokenValid || profile.roles.length < 1) {
      dispatch(logout());
    }
  }, [profile]);



  const userRoles = profile.roles;

  const intersectRoles = userRoles.filter((role) => {return roles.includes(role);});
  const userHasAccess = intersectRoles.length > 0;

  if (userHasAccess) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  } else {
    detach(() => {
      navigate(errorPages.notFoundPage.route.pageNotFound);
    });

    return (<></>);
  }
};

RouteGuard.propTypes = {
  children: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
};
