import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

export const Logo = ({ accent, width, sx }) => {
  return (<Box sx={{...(accent ? { backgroundColor: 'rgba(0, 0, 0, 0.25)' } : {})}}>
      <Box
        sx={{
          // border:'1px solid green',
          pl: 1.25,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
          width: width,
          pt:.75,
          height: { sm: 64, xs: 56 },
        }}
        component="a"
        href="/"
       >
        <Box>
          <Typography
            sx={{
              pl:.25,
              whiteSpace: 'nowrap',
              overflow: 'clip',
              color: '#FFFFFF',
              textTransform: 'uppercase',
              fontSize: '12px',
              textShadow: '.1em .1em 0.15em #000000',
              ...sx,
            }}>
            {process.env.REACT_APP_NAME}
          </Typography>
          <Box
            component="img"
            sx={{maxHeight: 64 / 3.2}}
            src="/logo.png"
          />
        </Box>
      </Box>
    </Box>
  );
};

Logo.defaultProps = {
  accent: true,
  width: 240,
};

Logo.propTypes = {
  accent: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  sx: PropTypes.object,
};
