/* eslint-disable no-param-reassign,no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import { detach } from '../utils/detach';
import { history } from '../utils/history';

const initialState = {
  email: '',
  name: '',
  imageUrl: null,
  authData: null,
  loginAt: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    login (state, action) {
      Object.assign(state, { ...action.payload, loginAt: new Date().getTime() });
      detach(() => {
        history.push('/');
      });
    },
    logout (state) {
      Object.assign(state, initialState);
      detach(() => {
        if (history.location.pathname !== '/login' && history.location.pathname !== '/login-admin') {
          // history.push(process.env.REACT_APP_ENV_NAME === 'DEV' ? '/login-admin' : '/login');
          history.push(process.env.REACT_APP_ENV_NAME === 'DEV' ? '/login' : '/login');
        }
      });
    },
  },
});

export const { login, logout } = profileSlice.actions;
export default profileSlice.reducer;
