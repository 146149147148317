import AudioIcon from '@mui/icons-material/Hearing';
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import {
  useEffect,
  useState,
} from 'react';

function MultiSelectList(
  { items, onChange, keyField, optionField, style, preSelectedItems },
) {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if(typeof preSelectedItems !== 'undefined') {
      setSelectedItems(preSelectedItems);
    }
  }, []);

  useEffect(() => {
    setSelectedItems(preSelectedItems);
  }, [preSelectedItems]);

  const handleItemToggle = (event)=> {
    const index = event.target.value;
    const selectedIndex = selectedItems.indexOf(index);
    const newSelectedItems = [...selectedItems];

    if (selectedIndex === -1) {
      newSelectedItems.push(index);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelectedItems);

    onChange(newSelectedItems);
  };

  return (
    <div style={style}>
      {/*{console.log(selectedItems)}*/}
      {items && items.length>0 && items.map((item) => {return (
        <div key={item[keyField]}>
          <Checkbox
            style={{marginRight:'8px', padding:'6px 0 6px 15px'}}
            checked={selectedItems.indexOf(item[keyField]) !== -1}
            onChange={handleItemToggle}
            value={item[keyField]}
            color="info"
            // icon={<AudioIcon/>}
            checkedIcon={<AudioIcon/>}
          />
          {item[optionField]}
        </div>
      );})}
    </div>
  );
}

MultiSelectList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  // id: oneOfType([PropTypes.string, PropTypes.nu]),
  keyField: PropTypes.string.isRequired,
  id: PropTypes.string,
  optionField: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // width: oneOfType([string, number]),
  style:PropTypes.object,
  preSelectedItems: PropTypes.arrayOf(PropTypes.string),
};

export default MultiSelectList;
