import GoogleIcon from '@mui/icons-material/Google';
import {
  Button,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  FormControlLabel,
  Switch,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import {
  useEffect,
  useState,
} from 'react';
import { isMobile }                          from 'react-device-detect';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  miscReduxData,
  setGlobalAlertData,
  setGlobalAppData,
} from '../../app/slices/miscSlice';
import { login } from '../../app/slices/profileSlice';
import { fetchJWT } from '../../app/utils/auth';
import { checkTokenValid } from '../../app/utils/checkTokenValid';
import { decodeToken } from '../../app/utils/decodeToken';
import { handleApiProblem } from '../../app/utils/handleApiProblem';
import { ShowViewPort }                      from '../../components';

export function LoginAdminPageMitRollen () {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const miscData = useSelector(miscReduxData);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [code, setCode] = useState(undefined);
  const [role, setRole] = useState([]);
  const [space, setSpace] = useState('VWD');
  const [useTokenOnly, setUseTokenOnly] = useState(false);
  const [token, setToken] = useState();

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {return onSuccessGoogleLogin(response);},
    onError: (error) => {return onErrorGoogleLogin(error);},
    flow: 'auth-code',
  });

  const onSuccessGoogleLogin = (response) => {
    if (response && response.code) {
      setCode(response.code);
    } else {
      handleApiProblem(response, (response) => {return dispatch(setGlobalAlertData(response));});
    }
  };

  const onErrorGoogleLogin = (error) => {
    handleApiProblem(error, (response) => {return dispatch(setGlobalAlertData(response));});
  };

  useEffect(() => {
   if(space) {
     dispatch(setGlobalAppData({ unternehmen: space }));
   }
  }, [space]);

  useEffect(() => {
    if (checkTokenValid()) {
      navigate('/');
    }
  }, []);

  const submitCode = () => {
    setRequestInProgress(true);
    fetchJWT(code, { fakeRoleAndSpaces: { roles: [role], spaces: [space] } }).then((response) => {
      if (useTokenOnly) {
        setToken(response.token);
      } else {
        const tokenData = (response.token && decodeToken(response.token));
        dispatch(login({ ...tokenData.userData, authData: { ...tokenData, token: response.token } }));
      }
    }).catch((error) => {
      handleApiProblem(error, (response) => {return dispatch(setGlobalAlertData(response));});
    }).finally(() => {return setRequestInProgress(false);});
  };

  const onSelectRole = (event) => {
    setRole(event.target.value);
  };
  const onSelectSpace = (event) => {
    setSpace(event.target.value);
  };

  function onSwitchTokenOnly (event) {
    setUseTokenOnly(event.target.checked);
  }

  const copyJwt = async () => {
    await navigator.clipboard.writeText(token);
    enqueueSnackbar('JWT ins Zwischenablage kopiert', { variant: 'success' });
  };

  return (
      <>
        {isMobile && <ShowViewPort/>}
        {code === undefined &&
            <Box sx={{
              display: 'flex',
              width: '100%',
              height: '500px',
              bgcolor: 'transparent',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Button
                  onClick={googleLogin}
                  disabled={requestInProgress}
                  sx={{
                    p: .7,
                    pl: 1.5, pr: 1.5,
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    boxShadow: 21,
                    background: 'linear-gradient(to bottom, #888888 5%, #000000 90%)',
                  }}
              >

                Anmelden
                <GoogleIcon sx={{
                  color: '#4285F4',
                  ml: 1,
                  mr: -0.5,
                  fontSize: '1.5em',
                }}/>

                {requestInProgress && <CircularProgress
                    size={34}
                    thickness={7}
                    sx={{
                      color: '#F4B400',
                      position: 'absolute',
                      left: 139,
                      zIndex: 1,
                    }}
                />}
              </Button>
            </Box>
        }
        {code !== undefined &&
            <Card style={{ width: '400px', margin: '48px auto', padding: '12px' }}>
              <CardContent>
                <>
                  <Typography variant="h6" component="h6">
                    Anmelden abschliessen -
                    {' '}
                    {process.env.REACT_APP_NAME}
                  </Typography>
                  <FormControl fullWidth sx={{marginTop: 3}}>
                    <InputLabel id="admin-login-select-role-label">Rolle</InputLabel>
                    <Select
                        labelId="admin-login-select-role-label"
                        id="admin-login-select-role"
                        value={role}
                        label="Rolle"
                        onChange={onSelectRole}
                    >
                      {
                        Object.values(miscData.userRoles).map((r) => {return <MenuItem key={r.value} value={r.value}
                                                                               title={r.description}>{r.label}</MenuItem>;})
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{marginTop: 3}}>
                    <InputLabel id="admin-login-select-space-label">Space</InputLabel>
                    <Select
                        labelId="admin-login-select-space-label"
                        id="admin-login-select-space"
                        value={space}
                        label="Space"
                        onChange={onSelectSpace}
                    >
                      {
                        Object.values(miscData.userSpaces).map((r) => {return <MenuItem key={r.value} value={r.value}
                                                                               title={r.description}>{r.label}</MenuItem>;})
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{marginTop: 3, ml: 1}}>
                    <FormControlLabel control={<Switch onChange={onSwitchTokenOnly}/>} label="JWT anzeigen"/>
                  </FormControl>
                  <FormControl fullWidth sx={{padding: 1, marginTop: 3}}>
                    <Button
                        onClick={submitCode}
                        disabled={requestInProgress || !role}
                        sx={{
                          p: .7,
                          pl: 1.5, pr: 1.5,
                          fontWeight: 'bold',
                          fontSize: '1.2em',
                          boxShadow: 21,
                          background: 'linear-gradient(to bottom, #888888 5%, #000000 90%)',
                        }}
                    >Weiter mit selected Rolle
                    </Button>
                  </FormControl>
                </>
                {token && useTokenOnly && <FormControl fullWidth sx={{ padding: 1, marginTop: 3 }}>
                  <InputLabel htmlFor="jwt-abc">JWT</InputLabel>
                  <OutlinedInput
                      id="jwt"
                      value={token}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="copy jwt"
                              onClick={copyJwt}
                              edge="end"
                          >
                            copy
                          </IconButton>
                        </InputAdornment>
                      }
                      label="JWT"
                  />
                </FormControl>
                }
              </CardContent>
            </Card>
        }
      </>
  );
}
