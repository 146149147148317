import {
  Box,
  Modal,
} from '@mui/material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ModalWrapper = ({ children, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{zIndex:65000}}
    >
      <Box sx={style}>
        {children}
      </Box>
    </Modal>
  );
};

ModalWrapper.defaultProps = {
  children: null,
  open: false,
  onClose: () => {
  }, // noop
};

ModalWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

