import {Box} from "@mui/material";
import {
  useEffect,
  useState,
} from 'react';
import {useSelector} from "react-redux";

const ShowInternet = () => {
  const globalAppData = useSelector((state) => {return state.misc.globalAppData;});

  const [url, setUrl] = useState();

  useEffect(() => {
        if(globalAppData.unternehmen=='MIDEWA') {setUrl("https://service.veolia-wasser.de/havarie/internetMidewa");}
        if(globalAppData.unternehmen=='VWD') {setUrl("https://service.veolia-wasser.de/havarie/internetVwd");}
  }, [globalAppData]);

  return (
    <>
      <Box sx={{height:'85vh', overflow:'hidden'}}>
      <iframe
          style={{width:'100%', height:'85vh', border:'none', overflow:'auto'}}
        // src={"https://service.vwd-gmbh.de/havarie/internetVwd"}
        src={url}
        />
      </Box>
    </>
  );
};

export default ShowInternet;