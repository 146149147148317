import { axiosInstance } from '../axiosInstance';
import {
  getTokenUrl,
  getWebAppId,
} from '../functions';

export const fetchJWT = async (authCode, extra = {}) => {
  const webappId =  getWebAppId();
  const tokenUrl = getTokenUrl();

console.log(webappId, tokenUrl);
  const response = await axiosInstance.post(
    tokenUrl, { authCode, webappId, ...extra });
  return response.data;
};