export const USER_ROLE = {
  ROLE_FACHADMIN: "HAVARIE_FACHADMIN",
  ROLE_USER: "HAVARIE_USER",
};

export const USER_SPACE = {};

export const userRoleLabel = (miscData, currentUserRoles) => {
  const currentRoles = miscData.userRoles.filter(
    (role) => {return currentUserRoles.filter((curRole) => {return role.value === curRole;}).length > 0;},
  );

  return currentRoles.length > 0 ? currentRoles[0].label : 'unknown role';
};
