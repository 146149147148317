import { storeReference } from '../../index';

export const checkTokenValid = () => {
  const {authData, loginAt} = storeReference.getState().profile;

  if (authData !== null && authData.exp) {
    const expireOnDate = new Date(Number(authData.exp + '000'));

    return new Date().getTime() < expireOnDate.getTime();
  }

  if (authData === null || authData.expires_in === undefined || !loginAt) {
    return false;
  }

  const now = new Date().getTime();
  const expireInTime = loginAt + (authData.expires_in * 1000);

  return now < expireInTime;
};
