import {FormControl, MenuItem, Select, Typography} from "@mui/material";
import {
  useEffect,
  useState,
} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {setGlobalAppData } from "../app/slices/miscSlice";

//const listUnternehmen =['MIDEWA','VWD'];

const SelUnternehmen = () => {

  const dispatch = useDispatch();
  const globalAppData = useSelector((state) => {return state.misc.globalAppData;});
  const profile = useSelector((state) => {return state.profile;});
  // const [selectedUnternehmen, setSelectedUnternehmen] = useState();
  const [listUnternehmen, setListUnternehmen] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // setListUnternehmen(['VWD']);
    if(globalAppData.unternehmen!=='...') {
      // setSelectedUnternehmen(globalAppData.unternehmen);
    } else {
     // setOpen(true);
    }
  }, []);

  useEffect(() => {
    // if( profile.roles.indexOf("HAVARIE_FACHADMIN") !== -1) {
    //   setListUnternehmen(['VWD','MIDEWA']);
    // } else {

      setListUnternehmen(profile.spaces);
      //setSelectedUnternehmen(profile?.space[0]?? undefined);
      // if(profile.spaces.length==1) {
      //   alert('setBySelect => ' + profile.spaces[0]);
      //   dispatch(setGlobalAppData({unternehmen:profile.spaces[0]}));
      // }

    // }

  }, [profile]);

  const handleChange = (event) => {
    // setSelectedUnternehmen(event.target.value);
    setOpen(false);
    dispatch(setGlobalAppData({unternehmen:event.target.value}));
  };

  return (
      <>
        <FormControl fullWidth size="small" >


          { listUnternehmen && listUnternehmen.length===1 &&
              <Typography
              sx={{ marginLeft:'10px'}}
              >{listUnternehmen[0]}</Typography>
          }

          { listUnternehmen && listUnternehmen.length>1 &&

          <Select
              sx={{bgcolor: '#ffffff'}}
              style={{minWidth: '97%'}}
              id="selectUnternehmen"
              open={open}
              onOpen={() =>{return setOpen(true);}}
              onClose={() => {return setOpen(false);}}
              value={ profile && profile.spaces.length > 0 && profile.spaces[0]}
              onChange={handleChange}
          >

            { listUnternehmen &&
                listUnternehmen.map((item) => {
                  return (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                  );
                })
            }
          </Select>
          }
        </FormControl>
      </>

  );

};

export default SelUnternehmen;
