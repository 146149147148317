import RefreshIcon  from '@mui/icons-material/RefreshRounded';
import {
  Box,
  Button,
  Card,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { format }   from "date-fns";
import {
  useEffect,
  useState,
}                   from 'react';
import { isMobile } from 'react-device-detect';
import {
  useDispatch,
  useSelector,
}                   from 'react-redux';

import {
  setGlobalAlertData,
  setGlobalAppData,
}                           from "../app/slices/miscSlice";
// import { logout }             from '../app/slices/profileSlice';
import * as API             from "../app/utils/ApiFetch";
import {ShowViewPort} from '../components';
import Havariemeldung       from "../components/Havariemeldung";
import HavariemeldungCard   from '../components/HavariemeldungCard';
import HavariemeldungMobile from '../components/HavariemeldungMobile';


const alertConfig = {
  show: true,
  title: 'Achtung',
  subtitle: 'XXXXXXXXX',
};

const meldungTemplate = {
  id: null,
  istAktiv: false,
  istAnrufbeantworter: false,
  istInternet: false,
  meldungGueltigAb: null,
  meldungGueltigBis: null,
  meldungText: '',
  meldungTitle: '',
  unternehmen: '...',
};

const HomePage = () => {
  // const profile = useSelector((state) => state.profile);
  const globalAppData = useSelector((state) => {
    return state.misc.globalAppData;
  });
  const dispatch = useDispatch();

  const [meldungen, setMeldungen] = useState([]);
  const [aktiveMeldungExists, setAktiveMeldungExists] = useState(-1);
  const [currentDate, setCurrentDate] = useState(format(new Date(), 'dd.MM.yyyy HH:mm'));
  const [showEditMeldung, setShowEditMeldung] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(meldungTemplate);
  const [alleMeldungen, setAlleMeldungen] = useState();
  const [showAlleMeldungen, setShowAlleMeldungen] = useState(false);

  const testAktiv = (me) => {
    if (!me.istAktiv) {
      return false;
    }
    const d = new Date().getTime();
    if ((d > new Date(me.meldungGueltigBis).getTime()) || (d < new Date(me.meldungGueltigAb).getTime())) {
      return false;
    }
    return true;
  };

  const getAktivierteMeldungen = (unternehmen) => {
    setCurrentDate(format(new Date(), 'dd.MM.yyyy HH:mm'));
    API.GET('/meldung',
      {
        'filter[unternehmen]': unternehmen,
        'filter[istAktiv]': true,
      },
    )
      .then((data) => {
        const me = data._embedded.filter(testAktiv);

        setAktiveMeldungExists(me.length);
        setMeldungen(me);

      })
      .catch((error) => {
        alertConfig.subtitle = "Fehler beim Laden der Meldungen - " + error.code;
        dispatch(setGlobalAlertData(alertConfig));
      });
  };

  const getAlleMeldungen = (unternehmen) => {
    // setCurrentDate(format(new Date(), 'dd.MM.yyyy HH:mm'));
    console.log('laden alle');
    API.GET('/meldung',
      {'filter[unternehmen]': unternehmen},
    )
      .then((data) => {
        const me = data._embedded;

        setAlleMeldungen(me);

      })
      .catch((error) => {
        alertConfig.subtitle = "Fehler beim Laden der Meldungen - " + error.code;
        dispatch(setGlobalAlertData(alertConfig));
      });
  };
  useEffect(() => {

    if (globalAppData.unternehmen !== '...') {
      getAktivierteMeldungen(globalAppData.unternehmen);
    } else {
      dispatch(setGlobalAppData({ unternehmen: 'VWD' }));
      //setGlobalAppData({unternehmen:'VWD'});
      // alert('changeGlobalAppData - ' + globalAppData.unternehmen);
      console.log('changeGlobalAppData - ' + globalAppData.unternehmen);
    }
  }, [globalAppData]);

  useEffect(() => {
    // console.log(globalAppData);
    // console.log(profile);

  }, []);

  const meldungDeleted = () => {
    getAktivierteMeldungen(globalAppData.unternehmen);
  };

  const meldungBearbeiten = (meldung) => {
    setCurrentEdit(meldung);
    console.log('meldung edit');
    setShowEditMeldung(true);
  };

  const onMobileEditSaved = (meldung) => {
    console.log(meldung);
    //setShowEditMeldung(false);
  };
  const onMobileEditClose = () => {
    setShowEditMeldung(false);
    setCurrentEdit(meldungTemplate);
    getAktivierteMeldungen();
  };

  return (
    <>
      <ShowViewPort/>

      {isMobile && <>

        {showEditMeldung &&
          <HavariemeldungMobile
            meldungData={currentEdit}
            onClose={onMobileEditClose}
            onSave={onMobileEditSaved}
          />}

        {!showEditMeldung && <>
          {aktiveMeldungExists == 0 && !showAlleMeldungen && globalAppData.unternehmen !== '...' &&
            <Card
              style={{ width: '100%', marginTop: '150px', paddingRight: '5px', paddingTop: '20px' }}>

              <Box
                sx={{ textAlign: 'center' }}
              >
                <Typography
                  sx={{
                    marginLeft: '5px',
                    textShadow: '5px 5px 5px gray',
                    textAlign: 'center',
                    fontSize: '2.5em',
                  }}>aktuell sind keine<br></br>Meldungen aktiv</Typography>
                <Typography
                  sx={{ marginTop: '30px', fontSize: '1.5em' }}
                >
                  {currentDate}
                </Typography>
              </Box>


              {/*<Button onClick={handleLogout}>Abmelden</Button>*/}
            </Card>
          }

          {  globalAppData.unternehmen !== '...' &&
            <Box sx={{
              display: 'inline-block',
              mt: '45px',
              height: 'calc(100vh - 165px)',
              overflow: 'auto',
            }}>
              { !showAlleMeldungen && aktiveMeldungExists > 0 &&
                meldungen.map((meldung) => {
                  return (
                    <>
                      <HavariemeldungCard meldungData={meldung} onEditClick={() => { meldungBearbeiten(meldung); }}/>
                    </>
                  );
                })
              }

              {alleMeldungen && showAlleMeldungen && <>
                {
                  alleMeldungen.map((meldung) => {
                    return (
                      <>
                        <HavariemeldungCard meldungData={meldung} onEditClick={() => {
                          meldungBearbeiten(meldung);
                        }}/>
                      </>
                    );
                  })
                }
              </>
              }


            </Box>
          }

          <Box
            sx={{ display: 'inline-block', position: 'absolute', top: 'calc(100vh - 100px)', left: '15px', width:'calc(100% - 25px)' }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
            <Button variant="contained"
                    onClick={() => {
                      console.log('meldung edit');
                      setCurrentEdit(meldungTemplate);
                      setShowEditMeldung(true);
                    }}
            >neue Meldung erstellen</Button>

            {!showAlleMeldungen &&
            <Button onClick={ () => { getAlleMeldungen('VWD'); setShowAlleMeldungen(true); }}>Alle anzeigen</Button>
            }
            {showAlleMeldungen &&
              <Button onClick={ () => { getAktivierteMeldungen('VWD'); setShowAlleMeldungen(false); }}>nur aktive anzeigen</Button>
            }
            </Stack>
          </Box>

        </>
        }
      </>
      }


      {!isMobile && globalAppData.unternehmen !== '...' &&
        <Box style={{ maxHeight: '80vh', overflow: 'auto', width: '1000px' }}>
          {
            aktiveMeldungExists == 0 &&
            <>
              <Typography variant="h4"
                          sx={{
                            marginLeft: '50px',
                            marginTop: '50px',
                            textShadow: '5px 5px 5px gray',
                          }}
              >aktuell sind keine Meldungen aktiv</Typography>

              <Chip
                sx={{ marginLeft: '50px' }}
                avatar={<RefreshIcon/>}
                label={currentDate}
                onClick={() => {
                  return getAktivierteMeldungen(globalAppData.unternehmen);
                }}
              />
            </>
          }

          {aktiveMeldungExists > 0 &&
            <>
              <Chip
                sx={{ marginBottom: '10px' }}
                avatar={<RefreshIcon/>}
                label={currentDate}
                onClick={() => {
                  return getAktivierteMeldungen(globalAppData.unternehmen);
                }}
              />
              {!isMobile && meldungen && meldungen.map((meldung) => {
                return (
                  <Havariemeldung
                    key={meldung.id}
                    id={meldung.id}
                    meldungData={meldung}
                    onDelete={meldungDeleted}
                  />
                );
              })
              }

              {isMobile && meldungen && meldungen.map((meldung) => {
                return (
                  <Typography key={meldung.id}>{meldung.meldungText}</Typography>
                );
              })
              }
            </>
          }

        </Box>
      }



    </>

  );
};

export default HomePage;
