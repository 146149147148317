import {Typography} from '@mui/material';

const Copyright = () => {return (
    <>
      <div style={{position: 'absolute', left: 'calc(100% - 390px)', top: '100%', marginTop: '-35px'}}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © Veolia '}
          {process.env.REACT_APP_NAME}
          {' v'}
          {process.env.REACT_APP_VERSION}
          {'  '}
          {new Date().getFullYear()}
        </Typography>
      </div>
    </>
);};

export default Copyright;
