import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import {
  format,
  parseISO,
}                      from "date-fns";
import PropTypes       from 'prop-types';
import {
  useEffect,
  useState,
}                      from 'react';
import { useDispatch } from 'react-redux';

import { setGlobalAlertData } from '../app/slices/miscSlice';
import * as API               from '../app/utils/ApiFetch';


const alertConfig = {
  show: true,
  title: 'Achtung',
  subtitle: 'XXXXXXXXX',
};

const HavariemeldungCard = ({ meldungData, onEditClick }) => {

  const dispatch = useDispatch();
  const [meldung, setMeldung] = useState();
  const [anrufBeantworter, setAnrufbeantworter] = useState();

  useEffect(() => {
    setMeldung(meldungData);
    fetchSelectedAnrufbeantworter(meldungData.id);
  }, []);

  const meldungBearbeiten = () => {
    onEditClick();
  };

  const fetchSelectedAnrufbeantworter = (id) => {
    const path = "/meldung/" + id + "/anrufbeantworter";
    API.GET(path)
      .then((data) => {
        const bezeichnungen = data._embedded.map((ab) => {
          return ab.bezeichnung;
        });

        setAnrufbeantworter(bezeichnungen.join(' | '));

      })
      .catch((error) => {
        alertConfig.subtitle = "Fehler beim Laden der Anrufbeantworter - " + error.code;
        dispatch(setGlobalAlertData(alertConfig));
      });
  };

  return (

    <>
      {meldung &&
        <Card
          variant="outlined"
          key={meldung.id}
          sx={{
            marginTop: '8px',
            marginRight: '5px',
            overflow: 'scroll-y',
          }}
        >
          <CardContent >
            {anrufBeantworter && <Typography sx={{
              border: '1px solid #10331033',
              padding: '2px 0 2px 3px ',
              backgroundColor: '#A2FF00FF',
            }}>{anrufBeantworter}</Typography>}
            {!anrufBeantworter &&
              <Typography sx={{ border: 'none', padding: '2px' }}>kein Anrufbeantworter</Typography>}
            <Typography
              sx={{ mt: '9px', fontWeight:'bold' }}
            >{meldung.meldungTitle}
            </Typography>
            <Typography
              sx={{ mt: '9px' }}
            >{format(parseISO(meldung.meldungGueltigAb), 'dd.MM.yyyy HH:mm')} - {format(parseISO(meldung.meldungGueltigBis), 'dd.MM.yyyy HH:mm')} </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{marginBottom:0}}

            >
            <Button
              sx={{ mt: '3px' }}
              onClick={() => {
                meldungBearbeiten();
              }}
            >bearbeiten</Button>
            <Typography
              sx={{backgroundColor:meldung.istAktiv ? '#A2FF00FF' : '#eee', padding:'1px 5px 1px 5px'}}>{meldung.istAktiv ? 'aktiv' : 'inaktiv'}</Typography>
            </Stack>
          </CardContent>
        </Card>
      }
    </>
  );
};

HavariemeldungCard.propTypes = {
  meldungData: PropTypes.object,
  onEditClick: PropTypes.func,
};
export default HavariemeldungCard;