import {
  Box,
  Typography,
}                      from '@mui/material';
import { useSelector } from 'react-redux';

const viewport_w = window.innerWidth;
const viewport_h = window.innerHeight;

export const ShowViewPort = () => {

  const globalAppData = useSelector((state) => {
    return state.misc.globalAppData;
  });

  return (
    <Box
      sx={{
        display: 'inline-block', position: 'absolute',
        overflow: 'hidden',
        // left: 'calc(100% - 120px)',
        left: '40px',
        zIndex:65000,
        // top: 'calc(100% - 30px)', w: '100vh',
        top: '1px', w: '100vh',
        // border: '1px solid #DDD',
      }}
    >
      <Typography
        sx={{ fontSize: '0.9em', p: '0 5px 0 5px' }}
      >{viewport_w}, {viewport_h} {globalAppData.unternehmen}</Typography>
    </Box>
  );
};