import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  isPast,
  isWeekend,
} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import { PropTypes } from 'prop-types';

const iso4y2m2d = (date) => {
  if (!date) {
    return;
  }
  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1);
  const day = dateObject.getDate();
  const iso4y2m2d = `${year}-${month.toString().padStart(2, 0)}-${day.toString().padStart(2, 0)}`;

  return iso4y2m2d;
};

const DatePickerWrapper = (
    {style, label, value, disabled, availableDates, withWeekend, withPast, onChange},
) => {

  const shouldDateBeDisabled = (date) => {
    if (availableDates && availableDates.length === 0) {
      return isWeekend(date);
    }

    // von VA-Spooler verbessert
    if (availableDates && availableDates.length > 0) {
      return availableDates.filter((availableDate) => {
        return !isWeekend(availableDate) && availableDate.getTime() === date.getTime();
      }).length < 1;
    }

    const weekend = !withWeekend ? isWeekend(date) : false;
    const past = !withPast ? isPast(date) : false;

    return (weekend || past);
  };

  const handleDatePickerChange = (date) => {
    const transformedDate = iso4y2m2d(date);
    onChange(transformedDate);
  };

  return (
      <div style={style}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
          <DatePicker
              showDaysOutsideCurrentMonth={true}
              views={["year", "month", "day"]}
              label={label}
              value={value || null}
              shouldDisableDate={shouldDateBeDisabled}
              disabled={disabled}
              onChange={handleDatePickerChange}
              slotProps={{
                // toolbar: {
                //   toolbarFormat: 'yyyy',
                //   toolbarPlaceholder: '??',
                // },
                // The actions will be the same between desktop and mobile
                actionBar: {actions: ["today"]},
              }}
              // calendarActions={[
              //   {
              //     icon: <ClearIcon/>, // You can use an appropriate icon from your icon library
              //     label: 'Today',
              //     onClick: () => alert(1),
              //   },
              // ]}
              // slotProps={{ textField: { variant: 'outlined' } }}

          />
        </LocalizationProvider>
      </div>
  );
};

DatePickerWrapper.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  availableDates: PropTypes.array,
  withWeekend: PropTypes.bool,
  withPast: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DatePickerWrapper;
