import { useSelector } from 'react-redux';

import { userRoutingRightsMap } from '../../App';

export const  routeAccessPermitted = (path) => {
  const profile = useSelector((state) => {return state.profile;});
  const userRoles = profile?.authData?.userData?.roles;
  if (!userRoles || !userRoutingRightsMap[path] || userRoutingRightsMap[path].roles.length === 0) {
    return false;
  }
  const permitted =  userRoles.filter((role) =>{return userRoutingRightsMap[path].roles.includes(role);}).length > 0;

  return permitted;
};
