
export const getApiUrl = () => {
  if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEV) {
    return process.env.REACT_APP_API_URL_DEV;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_PROD) {
    return process.env.REACT_APP_API_URL_PROD;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_INTERNET) {
    return process.env.REACT_APP_API_URL_INTERNET;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEVPHONE) {
    return process.env.REACT_APP_API_URL_DEVPHONE;
  }
  return 'API Url not Found';
};

export const getTokenUrl = () => {
  if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEV) {
    return process.env.REACT_APP_AUTH_TOKEN_URL_DEV;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_PROD) {
    return process.env.REACT_APP_AUTH_TOKEN_URL_PROD;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_INTERNET) {
    return process.env.REACT_APP_AUTH_TOKEN_URL_INTERNET;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEVPHONE) {
    return process.env.REACT_APP_AUTH_TOKEN_URL_DEV;
  }
  return 'Token Url not Found';
};

export const getWebAppId = () => {
  if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEV) {
    return process.env.REACT_APP_WEBAPP_ID_DEV;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_PROD) {
    return process.env.REACT_APP_WEBAPP_ID_PROD;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_INTERNET) {
    return process.env.REACT_APP_WEBAPP_ID_INTERNET;
  } else if (window.location.hostname === process.env.REACT_APP_HOSTNAME_DEVPHONE) {
    return process.env.REACT_APP_WEBAPP_ID_DEVPHONE;
  }
  return 'WebAppId not Found';
};