import {
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import {
  NavLink,
  useLocation,
} from 'react-router-dom';


import { routeAccessPermitted } from '../app/utils/routeAccessPermitted';


const subNavStyle = { display: 'inline', lineHeight:1, fontSize: "14px", textShadow: ".1em .1em .01em #FFFFFF" };
const selected = (isActive, route, location) => {
  return isActive ? true : location.pathname.indexOf(route) > -1;
};

const NavElement = ({ route, title, tooltip, subtitle, icon, parentSx, parentActiveColor, iconCallback}) => {
  const location = useLocation();

  return (
    routeAccessPermitted(route) && <NavLink
      end
      // reloadDocument={route === location.pathname}
      to={route}
      children={({ isActive }) => {
        const active = selected(isActive, route, location);
        return (
          <ListItemButton selected={active} title={tooltip} sx={{
            ...(active ? {
              borderRight: 4,
              borderColor: parentActiveColor,
            } : {}),
            m:0, p:0, pl:2.75,
          }}>
            {icon || iconCallback(active ? parentActiveColor: (theme) => {return theme.palette.text.primary;})}
            <ListItemText
              sx={{ml:.5}}
              primary={<>
                <Typography sx={{ ...subNavStyle, ...parentSx }}
                            component="span"
                            color={active ? parentActiveColor : (theme) => {return theme.palette.text.primary;}}>
                  {title}
                </Typography>
              </>}
              secondary={<>
                <Typography sx={{ ...subNavStyle, fontWeight:"398", fontSize: "12px" }}
                            component="span"
                            variant="subtitle2">
                  {subtitle}
                </Typography>
              </>}/>
          </ListItemButton>
        );
      }}/>
  );
};

NavElement.defaultProps = {iconCallback: () => {return null;}};

NavElement.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.object,
  iconCallback: PropTypes.func,
  parentSx: PropTypes.object,
  parentActiveColor: PropTypes.string,
};
export { NavElement };
