import {Box} from "@mui/material";
import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {setGlobalAlertData} from "../app/slices/miscSlice";
import * as API from "../app/utils/ApiFetch";
import Havariemeldung from "../components/Havariemeldung";


const alertConfig = {
  show: true,
  title: 'Achtung',
  subtitle: 'XXXXXXXXX',
};

const VerwaltungMeldungen = () => {
  // const profile = useSelector((state) => state.profile);
  const globalAppData = useSelector((state) => {return state.misc.globalAppData;});
  const dispatch = useDispatch();

  const [meldungen, setMeldungen] = useState([]);

  const getMeldungen = (unternehmen) => {
    API.GET('/meldung', { 'filter[unternehmen]': unternehmen })
        .then( (data) => {
          setMeldungen(data._embedded);
        } )
        .catch((error) => {
          alertConfig.subtitle = "Fehler beim Laden der Meldungen - " + error.code;
          dispatch(setGlobalAlertData(alertConfig));
        });
  };

  useEffect(() => {
    if(globalAppData.unternehmen !== '...') {getMeldungen(globalAppData.unternehmen);}
  }, [globalAppData]);

  useEffect(() => {
    // console.log(globalAppData);
    // console.log(profile);
  }, []);

  const meldungDeleted = (id) => {
    const m = meldungen.filter((m) => {return m.id !== id;});
    setMeldungen(m);
  };

  return (
      <>
        <Box style={{maxHeight: '80vh', overflow: 'auto', width:'1000px' }}>
          { globalAppData.unternehmen !=="..." &&
              <Havariemeldung/>
          }
          { meldungen && meldungen.map((meldung) => {

            return (
                <Havariemeldung
                    key={meldung.id}
                    id={meldung.id}
                    meldungData={meldung}
                    onDelete={meldungDeleted}
                />
            );
          })
          }

        </Box>
      </>
  );
};

export default VerwaltungMeldungen;
