import {
  Box,
  LinearProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';

import { axiosInstance } from '../app/axiosInstance';

export const PreloaderLinear = (props) => {
  const [progress, setProgress] = useState(0);
  const [activePreloader, setActivePreloader] = useState(false);

  useEffect(() => {
    if (!activePreloader) {
      setProgress(0);
    }

    const timer = setInterval(() => {
      setProgress((prevProgress) => {return (prevProgress > 100 ? 0 : prevProgress + 4);});
    }, 300);

    axiosInstance.interceptors.request.use(
      (request) => {
        setActivePreloader(true);
        setProgress(0);
        return request;
      },
      (error) => {
        setActivePreloader(false);
        setProgress(0);
        return Promise.reject(error);
      },
    );
    axiosInstance.interceptors.response.use(
      (response) => {
        setProgress(101);
        setActivePreloader(false);
        return response;
      },
      (error) => {
        setProgress(101);
        setActivePreloader(false);
        return Promise.reject(error);
      });

    return () => {
      clearInterval(timer);
      setActivePreloader(false);
    };

  }, []);

  return (
    <Box sx={{ width: '100%', height: '7px' }}>
      {activePreloader && <LinearProgress {...{ ...props, value: props.value ?? progress }} sx={{ height: '7px' }}/>}
    </Box>
  );
};

PreloaderLinear.defaultProps = {
  color: 'info',
  variant: 'determinate',
};

PreloaderLinear.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning', 'inherit']),
  classes: PropTypes.any,
  sx: PropTypes.object,
  value: PropTypes.number,
  valueBuffer: PropTypes.number,
  variant: PropTypes.oneOf(['determinate', 'indeterminate', 'buffer', 'query']),
};
