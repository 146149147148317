import {
  createTheme,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {SnackbarProvider} from 'notistack';
import { isMobile } from 'react-device-detect';
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';

import {history} from './app/utils/history';
import {USER_ROLE} from "./app/utils/userRolesConst";
import * as Component from './components';
import * as Page from './pages';
import t9nError from './pages/Error/t9n/de.json';

const themeMobile = createTheme({ });

const themeDesktop = createTheme({
  palette: {
    text: {
      primary: 'rgb(52, 58, 64, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    primary: {main: 'rgb(52, 58, 64, 0.9)'},
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          paddingTop: '14px',
          paddingBottom: '14px',
          whiteSpace: 'nowrap',
          overflowX: 'clip',

        },
        sortActive: {
          fontWeight: 'bold',
          textDecoration: 'underline',
          borderRadius: '4px',
          color: '#272727',
          paddingLeft: '5px',
          paddingRight: '5px',
          backgroundColor: 'rgba(254,228,175,0.98)',
        },
        sortAction: {
          minWidth: '40px',
          maxWidth: '135px',
        },
      },
    },
    MuiButton: {
      defaultProps: {variant: 'contained'},
      styleOverrides: {root: {color: 'primary'}},
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          margin: 8,
          borderWidth: 0,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          padding: 0,
          margin: 8,
          borderWidth: 0,
        },
      },
    },
  },
});

export const userRoutingRightsMap = {
  ['/meldungen-bearbeiten']: {
    component: <Page.VerwaltungMeldungen/>,
    roles: [
      USER_ROLE.ROLE_FACHADMIN,
      USER_ROLE.ROLE_USER,
    ],
  },
  ['/aktuelle-meldungen']: {
    component: <Page.HomePage/>,
    roles: [
      USER_ROLE.ROLE_FACHADMIN,
      USER_ROLE.ROLE_USER,
    ],
  },
  ['/show-internet']: {
    component: <Page.ShowInternet/>,
    roles: [
      USER_ROLE.ROLE_FACHADMIN,
      USER_ROLE.ROLE_USER,
    ],
  },
  ['/']: {
    component: <Page.HomePage/>,
    roles: [
      USER_ROLE.ROLE_FACHADMIN,
      USER_ROLE.ROLE_USER,
    ],
  },
};

const App = () => {
  return (
    <HistoryRouter history={history} >
      <Component.LoginViaSharedToken>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLECLIENTID}>

          <CssBaseline/>
          <ThemeProvider theme={ isMobile ? themeMobile : themeDesktop }>
            <SnackbarProvider autoHideDuration={3000} maxSnack={3}
                              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <Component.Layout>
                <>
                  <Routes>
                    {/*<Route exact path="/" element={<Page.HomePage/>}/>*/}
                    {/*<Route exact path="/MeldungenBearbeiten" element={<Page.VerwaltungMeldungen/>}/>*/}

                    <Route exact path={t9nError.notFoundPage.route.pageNotFound} element={<Page.NotFoundPage/>}/>
                    <Route exact path="/login-admin" element={<Page.LoginAdminPageMitRollen/>}/>
                    {/*<Route exact path="/login-admin" element={<Page.LoginAdminPageMitRollen/>}/>*/}

                    <Route exact path="/login" element={<Page.LoginPageGoogleAuthCode/>}/>
                    {/*<Route exact path="/login" element={<Page.LoginPageGoogleAccessToken/>}/>*/}

                    {
                      Object.keys(userRoutingRightsMap).map((key) => {
                        return (
                            <Route exact path={key} key={key} element={
                              <Component.RouteGuard roles={userRoutingRightsMap[key].roles}>
                                {userRoutingRightsMap[key].component}
                              </Component.RouteGuard>
                            }/>
                        );
                      })
                    }

                    <Route exact path={t9nError.notFoundPage.route.pageNotFound} element={<Page.NotFoundPage/>}/>
                    <Route exact path={t9nError.forbidden.route.forbidden} element={<Page.ForbiddenPage/>}/>
                    <Route exact path={t9nError.unauthorized.route.unauthorized} element={<Page.UnauthorizedPage/>}/>
                  </Routes>
                  <Component.GlobalAlert/>
                </>
              </Component.Layout>
            </SnackbarProvider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </Component.LoginViaSharedToken>
    </HistoryRouter>
);};
export default App;
