import GoogleIcon         from '@mui/icons-material/Google';
import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import {
  useEffect,
  useState,
}                         from 'react';
import { isMobile }       from 'react-device-detect';
import { useDispatch }    from 'react-redux';
import { useNavigate }    from 'react-router-dom';

import {setGlobalAppData , setGlobalAlertData } from "../../app/slices/miscSlice";
import { login }              from '../../app/slices/profileSlice';
import { fetchJWT }           from '../../app/utils/auth';
import { checkTokenValid }    from '../../app/utils/checkTokenValid';
import { decodeToken }        from '../../app/utils/decodeToken';
import { handleApiProblem }   from '../../app/utils/handleApiProblem';
import { ShowViewPort }                         from '../../components';

const translate_map = { login: 'Anmeldung' };


export function LoginPageGoogleAuthCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [requestInProgress, setRequestInProgress] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
      return onSuccessGoogleLogin(response);
    },
    onError: (error) => {
      return onErrorGoogleLogin(error);
    },
    flow: 'auth-code',
  });

  const onSuccessGoogleLogin = (response) => {
    if (response && response.code) {
      submitCode(response.code);
    } else {
      dispatch(setGlobalAlertData(response));
    }
  };

  const onErrorGoogleLogin = (error) => {
    dispatch(setGlobalAlertData(error.response));
  };

  useEffect(() => {
    if (checkTokenValid()) {
      navigate('/');
    }
  }, []);

  const submitCode = (code) => {
    setRequestInProgress(true);
    fetchJWT(code).then((data) => {
      const tokenData = (data.token && decodeToken(data.token));
      console.log('token-userData', tokenData.userData.spaces[0]);
      dispatch(setGlobalAppData({unternehmen:tokenData.userData.spaces[0]}));
      dispatch(login({ ...tokenData.userData, authData: { ...tokenData, token: data.token } }));

      // if(tokenData.userData.spaces.length==1) {
      //   dispatch(setGlobalAppData({unternehmen:tokenData.userData.spaces[0]}));
      // }

    }).catch((error) => {
      handleApiProblem(error, (response) => {
        return dispatch(setGlobalAlertData(response));
      });
    }).finally(() => {
      return setRequestInProgress(false);
    });
  };

  return (

    <Box sx={{
      display: 'flex',
      width: '100%',
      height: isMobile ? '80vh' : '500px',
      bgcolor: 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
    }}>

      {isMobile &&
       <ShowViewPort/>
      }
      <Button
        onClick={googleLogin}
        disabled={requestInProgress}
        sx={{
          p: isMobile ? '15px' : .7,
          pl: isMobile ? '25px' : 1.5,
          pr: isMobile ? '25px' : 1.5,
          fontWeight: 'bold',
          fontSize: isMobile ? '1.5em' : '1.2em',
          boxShadow: 21,
          background: 'linear-gradient(to bottom, #888888 5%, #000000 90%)',
        }}
      >

        {translate_map.login}
        <GoogleIcon sx={{

          color: '#4285F4',
          ml: 1,
          mr: -0.5,
          fontSize: '1.5em',
        }}/>

        {requestInProgress && <CircularProgress
          size={34}
          thickness={7}
          sx={{
            color: '#F4B400',
            position: 'absolute',
            left: 139,
            zIndex: 1,
          }}
        />}
      </Button>
    </Box>

  );
}
